.social-container {
    align-items: center;
    justify-content: center;
}

.social {
    margin-top: 15px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.socialTitle {
    margin: 10px;
    padding: 5px;
    width: 80%;
    border-top: 1px solid #a7a4a4;
    border-bottom: 1px solid #a7a4a4;
    font-family: "Varela Round", sans-serif;
    font-size: 12px;
    color: #222;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

.socialIcon{
      font-size: 16px;
      margin-left: 10px;
      cursor: pointer;
}