.header {
  background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,1)),url("../../images/bg.png");
background-color: #111;
    margin-top: 20px;
  }
  
  .headerTitles {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Encode Sans', sans-serif;
    color: #444;
  }

  .headerTitleSm {
    position: absolute;
    top: 5%;
    font-size: 20px;
  }
  
  .headerTitleLg {
    position: absolute;
    top: 10%;
    font-size: 100px;
  }
  
  .headerImg {
    width: 100%;
    height: 450px;
    margin-top: 80px;
    object-fit: cover;
   }