.card {
    width: 385px;
    overflow: hidden;
    box-shadow: 0px 0px 15px -5px;
    margin: 25px 25px;
    transition: 0.5s;
    animation: ease-in-out;
}

.card:hover{
    transform: scale(1.1);
    box-shadow: 0px 0px 15px 0px;
}

.cardImg img {
    overflow: hidden;
    height: 200px;
}

.cardTitle {
    font-family: 'Josefin Sans', serif;
    font-size: 24px;
    font-weight: 700;
    margin-top: 15px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
