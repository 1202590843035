.rc-slider {
    width: 600px;
    margin: 50px 50px;
}

.sliderLabel {
    width: 100%;
    height: 50px;
    background-color: rgb(26, 28, 46);
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 999;
    font-family: "Josefin Sans", sans-serif;
    z-index: 999;
}