.dark {
  background-color: #202124;
  color: #f0f0f0;
}
.light {
  background-color: #f0f0f0;
  color: #202124;
}
  
.top {
    width: 100%;
    height: 50px;
    background-color: rgb(26, 28, 46);
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 999;
    font-family: "Josefin Sans", sans-serif;
    z-index: 999;
  }
  
  .topLeft {
    flex: 3;
    display: flex;
    align-items: left;
    justify-content: left;
  }
  .topRight {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .topIcon {
    font-size: 20px;
    margin-right: 10px;
    color: #444;
    cursor: pointer;
  }
  
  .topCenter {
    flex: 6;
  }
  
  .topList {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .topListItem {
    margin-right: 20px;
    font-size: 18px;
    font-weight: 300;
    color: #F0f0f0;
    cursor: pointer;
  }
  
  .topListItem:hover{
      color: gray;
  }
  
  .topImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
    cursor: pointer;
  }
  
  .topSearchIcon {
    font-size: 18px;
    color: #a4a7a7;
    cursor: pointer;
  }

  
  .topRight p {
    margin-right: 5px;
    font-size: 18px;
    font-weight: 300;
    color: #F0f0f0;
  }

  .toggleswtch {
    display: flex;
    margin-right: 20px;
  }